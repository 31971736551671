<script setup lang="ts">
import { storeToRefs } from 'pinia'
import {
  getCurrentLeaseId,
  useWebViewerStore,
  useCurrentLease,
} from '@register'
import { Product } from '~/composables/useAPI/types'
import { isSamePath, joinURL } from 'ufo'
import type { NavigationItem } from './types'

// TODO: Refactor navbar
const { $can } = useNuxtApp()
const router = useRouter()
const route = useRoute()
const leaseId = getCurrentLeaseId()
const divisionId = getCurrentDivisionId()

const { data: lease, product, isPending, isFetching } = useCurrentLease()
const { currentDocumentId } = storeToRefs(useWebViewerStore())

const documentsOptions = computed(
  () =>
    lease.value?.documents?.map(({ id, name, filename, type }) => {
      const label = `${title(type ?? '')}: ${name ?? filename}`
      return {
        label,
        value: id,
      }
    }) ?? [],
)

const createLeaseURL = (path = '') => {
  return joinURL(`/division/${divisionId}/lease/${leaseId}`, path)
}

const { visible: isSaving } = useRequestCounter('lease')

const tabs = computed(() => {
  if (!lease.value) return []

  const isSubLease = lease.value.isSubLease
  const isAccessingCurrentProduct =
    product.value.accessing === product.value.current
  const baseNavigationItems: NavigationItem[] = []

  baseNavigationItems.push({
    label: 'Summary',
    to: createLeaseURL(),
    icon: 'printer',
    exact: true,
  })

  if (product.value.accessing === Product.LEASE_MANAGER) {
    baseNavigationItems.push({
      label: 'Lease',
      to: createLeaseURL('manager'),
      icon: 'edit',
      isSubLease,
    })
  } else {
    baseNavigationItems.push({
      label: 'AI',
      to: createLeaseURL('lease'),
      icon: 'clipboard',
      isSubLease,
    })
  }

  if (isAccessingCurrentProduct) {
    baseNavigationItems.push({
      label: 'Amendments',
      to: createLeaseURL('amendments'),
      icon: 'file-attachment',
      total: lease.value.itemCounts?.variationCount,
    })
  }

  if (isAccessingCurrentProduct && $can('product', Product.CRITICAL_DATES)) {
    baseNavigationItems.push({
      label: 'Reminders',
      to: createLeaseURL('reminders'),
      icon: 'calendar-schedule-time',
    })
  }

  if (isAccessingCurrentProduct && lease.value.showSubleases) {
    if (isSubLease) {
      baseNavigationItems.push({
        label: 'Headlease',
        to: createLeaseURL('headlease'),
        icon: 'headlease',
      })
    } else {
      baseNavigationItems.push({
        label: 'Subleases',
        to: createLeaseURL('subleases'),
        icon: 'sublease',
        total: lease.value.itemCounts?.subleaseCount,
      })
    }
  }

  baseNavigationItems.push({
    label: 'Documents',
    to: createLeaseURL('documents'),
    icon: 'folder',
    total: lease.value.itemCounts?.documentCount,
  })

  return baseNavigationItems
})

const current = ref(tabs.value.find((tab) => tab.to === route.path))

const { width: widthWindow } = useWindowSize()

function changeRouteMobile(value: string) {
  if (isSamePath(value, route.path)) return
  router.push(value)
}
</script>

<template>
  <div
    v-if="widthWindow > 768"
    class="bg-gray-750/90 z-[105] flex h-14 w-full items-center justify-between border border-gray-700 px-4 shadow-[0_4px_30px_0px_rgba(0,0,0,0.35)] backdrop-blur-2xl before:absolute before:-right-0 before:left-0 before:top-[-20px] before:h-5 before:bg-gray-900/60 before:bg-opacity-80 before:backdrop-blur-2xl before:backdrop-saturate-[180%] before:content-[''] md:fixed print:hidden"
  >
    <ButtonGroup v-auto-animate>
      <ButtonGroupItem
        v-for="_tab in tabs"
        :key="_tab.to"
        :icon="_tab.icon"
        :exact="_tab.exact"
        :to="{
          path: _tab.to,
          query: {
            ...route.query,
          },
        }"
      >
        {{ _tab.label }}

        <span v-if="_tab.total" class="pl-1 text-xs text-gray-500">
          ({{ _tab.total }})
        </span>

        <span
          v-if="_tab.isSubLease"
          class="-mr-1 ml-1 rounded-lg bg-white/5 px-2 text-[10px] text-gray-200"
        >
          sublease
        </span>
      </ButtonGroupItem>
    </ButtonGroup>

    <div v-if="isSaving" class="flex items-center text-sm text-gray-500">
      <Spinner class="fill-gray-500" size="xs" />
      Saving...
    </div>

    <div class="mt-1 flex items-center space-x-2">
      <VTooltip
        class="inline-flex"
        :delay="10"
        placement="left"
        :distance="2"
        :auto-hide="true"
      >
        <Button
          color="link"
          class="flex h-8 w-8 p-0"
          icon="settings"
          :to="`/division/${divisionId}/lease/${leaseId}/settings`"
        >
        </Button>

        <template #popper>
          <div class="text-xs">Lease settings</div>
        </template>
      </VTooltip>

      <FormKit
        v-if="currentDocumentId && !route.meta.hidePdf"
        v-model="currentDocumentId"
        type="listbox"
        :options="documentsOptions"
        :loading="isFetching"
      />
    </div>
  </div>
  <div
    v-else
    class="bg-gray-750/90 relative z-[105] flex h-14 w-full items-center justify-between border border-gray-700 px-4 shadow-[0_4px_30px_0px_rgba(0,0,0,0.35)] backdrop-blur-2xl backdrop-saturate-[180%] before:absolute before:-right-0 before:left-0 before:top-[-20px] before:h-5 before:bg-gray-900/60 before:bg-opacity-80 before:backdrop-blur-2xl before:content-[''] md:fixed print:hidden"
  >
    <!-- <span class="absolute right-0 top-0 z-[10000] min-h-4 min-w-10 bg-green">
      {{ current?.label }}
    </span> -->
    <FormKit
      :value="current?.to"
      type="listbox"
      name="reviewPage"
      :options="tabs"
      label-by="label"
      track-by="to"
      validation="required"
      outer-class="w-full"
      @input="changeRouteMobile($event)"
    />
  </div>
</template>

<style lang="postcss" scoped>
:deep(.multiselect) {
  @apply cursor-pointer;
  .multiselect__tags {
    @apply flex pt-0;
  }
  .multiselect__single {
    @apply mb-0 line-clamp-2 min-w-[250px] max-w-[250px] self-center pt-0.5 text-xs;
  }

  .multiselect,
  .multiselect--active {
    .multiselect__select {
      @apply flex origin-center justify-center p-0;

      &::before {
        @apply !top-auto !m-0 flex h-[6px] origin-center transform items-stretch self-center;
      }
    }
  }

  .multiselect__content {
    @apply max-w-full;
  }
  .multiselect__content-wrapper {
    @apply overflow-x-hidden overflow-y-scroll scroll-smooth;
  }
  .multiselect__element {
    @apply truncate;
  }

  .multiselect__option {
    @apply max-w-full truncate text-xs;
  }
}
</style>
